import { ModelMixin } from '@app/models/core/base-generic.model';

/**
 * Card represents a credit card as store in Stripe.
 *
 * Companies are currently restricted to a single card.
 */
export class Card extends ModelMixin<Card>() {
    protected static _resource = 'billingCards';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['token'];

    set token(val: string) {
        this._attributes['token'] = val;
    }

    get brand(): string {
        return this._attributes['brand'];
    }
    get last4(): string {
        return this._attributes['last4'];
    }
    get expMonth(): string {
        return this._attributes['expMonth'];
    }
    get expYear(): string {
        return this._attributes['expYear'];
    }
    get name(): string {
        return this._attributes['name'];
    }
    get addressLine1(): string {
        return this._attributes['addressLine1'];
    }
    get addressCity(): string {
        return this._attributes['addressCity'];
    }
    get addressState(): string {
        return this._attributes['addressState'];
    }
    get addressZip(): string {
        return this._attributes['addressZip'];
    }
    get addressCountry(): string {
        return this._attributes['addressCountry'];
    }
}
